import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/layout.js";
import { Link } from 'gatsby';
import Q1Graph from '../../../../components/specific/CSEC/Physics/2006/Q1Graph-2006.jsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`May/June 2006 Paper 2`}</h1>
    <div className='tier1-3 ques'>
    <span>1</span>
    <span>A student was asked to investigate the variation in temperature of 2 kg of water initially at 30<sup>o</sup>C when heat energy was supplied to it by an electric heater rated at 1400 W.
    The following measurements were taken and recorded in Table 1.</span>
    </div>
    <table className='ques'>
    <tbody>
        <tr>
            <td>Temperature of water, &Theta;/<sup>o</sup>C</td>
            <td>30.0</td>
            <td>40.0</td>
            <td>51.0</td>
            <td>62.5</td>
        </tr>
        <tr>
            <td>Heating time, t/s</td>
            <td>0</td>
            <td>60</td>
            <td>120</td>
            <td>180</td>
        </tr>
        <tr>
            <td>Temperature change, &Delta;&Theta; = (&Theta;-30)/<sup>o</sup>C </td>
            <td>0</td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td>Energy supplied, Q = 1400t/J</td>
            <td>0</td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    </tbody>
    </table>
    <table className='ques'>
    <tbody>
        <tr>
            <td>Temperature of water, &Theta;/<sup>o</sup>C</td>
            <td>72.0</td>
            <td>80.0</td>
            <td>89.0</td>
        </tr>
        <tr>
            <td>Heating time, t/s</td>
            <td>240</td>
            <td>300</td>
            <td>360</td>
        </tr>
        <tr>
            <td>Temperature change, &Delta;&Theta; = (&Theta;-30)/<sup>o</sup>C </td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td>Energy supplied, Q = 1400t/J</td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    </tbody>
    </table>
    <div className='tier2 ques'>
    <span>(a)</span>
    <span>Complete the table above.</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong></p>
    <table>
    <tbody>
        <tr>
            <td>Temperature of water, &Theta;/<sup>o</sup>C</td>
            <td>30.0</td>
            <td>40.0</td>
            <td>51.0</td>
            <td>62.5</td>
        </tr>
        <tr>
            <td>Heating time, t/s</td>
            <td>0</td>
            <td>60</td>
            <td>120</td>
            <td>180</td>
        </tr>
        <tr>
            <td>Temperature change, &Delta;&Theta; = (&Theta;-30)/<sup>o</sup>C </td>
            <td>0</td>
            <td>10</td>
            <td>21</td>
            <td>32.5</td>
        </tr>
        <tr>
            <td>Energy supplied, Q = 1400t/J</td>
            <td>0</td>
            <td>84 <br />x 10<sup>3</sup></td>
            <td>168 x 10<sup>3</sup></td>
            <td>252 x 10<sup>3</sup></td>
        </tr>
    </tbody>
    </table>
    <table>
    <tbody>
        <tr>
            <td>Temperature of water, &Theta;/<sup>o</sup>C</td>
            <td>72.0</td>
            <td>80.0</td>
            <td>89.0</td>
        </tr>
        <tr>
            <td>Heating time, t/s</td>
            <td>240</td>
            <td>300</td>
            <td>360</td>
        </tr>
        <tr>
            <td>Temperature change, &Delta;&Theta; = (&Theta;-30)/<sup>o</sup>C </td>
            <td>42</td>
            <td>50</td>
            <td>59</td>
        </tr>
        <tr>
            <td>Energy supplied, Q = 1400t/J</td>
            <td>336 x 10<sup>3</sup></td>
            <td>420 x 10<sup>3</sup></td>
            <td>504 x 10<sup>3</sup></td>
        </tr>
    </tbody>
    </table>
    <div className='tier2 ques'>
    <span>(b)</span>
    <span>Plot on the graph page opposite a graph of energy supplied against temperature change.</span>
    </div>
    <p><strong parentName="p">{`Solution`}</strong></p>
    <p className='note'>
Please note that the following graph is drawn for demonstration purposes. 
      <p>{`The graph for the exam's solution should have a title, labels for the axes and other necessary information.`}</p>
    </p>
    <Q1Graph mdxType="Q1Graph" />
    <div className='tier2 ques'>
    <span>(c)</span>
    <span>Calculate the gradient of the graph.</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong></p>
    <p>{`The gradient is given by`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`Δ`}</mi><mi parentName="mrow">{`Q`}</mi></mrow><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`Δ`}</mi><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`Θ`}</mi></mrow></mfrac><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mrow parentName="mfrac"><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`(`}</mo><mn parentName="mrow">{`420`}</mn><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`168`}</mn><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`)`}</mo><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mn parentName="mrow">{`1`}</mn><msup parentName="mrow"><mn parentName="msup">{`0`}</mn><mn parentName="msup">{`3`}</mn></msup></mrow><mrow parentName="mfrac"><mn parentName="mrow">{`50`}</mn><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`21`}</mn></mrow></mfrac><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mrow parentName="mfrac"><mn parentName="mrow">{`252`}</mn><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mn parentName="mrow">{`1`}</mn><msup parentName="mrow"><mn parentName="msup">{`0`}</mn><mn parentName="msup">{`3`}</mn></msup></mrow><mn parentName="mfrac">{`29`}</mn></mfrac><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`8.69`}</mn><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mn parentName="mrow">{`1`}</mn><msup parentName="mrow"><mn parentName="msup">{`0`}</mn><mn parentName="msup">{`3`}</mn></msup><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`J`}</mi><msup parentName="mrow"><mi parentName="msup" {...{
                      "mathvariant": "normal"
                    }}>{`/`}</mi><mi parentName="msup">{`o`}</mi></msup><mi parentName="mrow">{`C`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`t`}</mi><mi parentName="mrow">{`o`}</mi><mtext parentName="mrow">{` `}</mtext><mn parentName="mrow">{`3`}</mn><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`s`}</mi><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`.`}</mi><mi parentName="mrow">{`f`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`= \\frac{\\Delta Q}{\\Delta \\Theta}\\\\ = \\frac{(420 - 168)~x~10^{3}}{50 - 21}\\\\ = \\frac{252~x~10^{3}}{29}\\\\ = 8.69~x~10^{3}~J/^{o}C~to~3~s.f`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.0463em",
                  "verticalAlign": "-0.686em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.3603em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`ΔΘ`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`Δ`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`Q`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.686em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.2604em",
                  "verticalAlign": "-0.7693em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.4911em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`50`}</span><span parentName="span" {...{
                              "className": "mspace",
                              "style": {
                                "marginRight": "0.2222em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mbin"
                            }}>{`−`}</span><span parentName="span" {...{
                              "className": "mspace",
                              "style": {
                                "marginRight": "0.2222em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}>{`21`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mopen"
                            }}>{`(`}</span><span parentName="span" {...{
                              "className": "mord"
                            }}>{`420`}</span><span parentName="span" {...{
                              "className": "mspace",
                              "style": {
                                "marginRight": "0.2222em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mbin"
                            }}>{`−`}</span><span parentName="span" {...{
                              "className": "mspace",
                              "style": {
                                "marginRight": "0.2222em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}>{`168`}</span><span parentName="span" {...{
                              "className": "mclose"
                            }}>{`)`}</span><span parentName="span" {...{
                              "className": "mspace nobreak"
                            }}>{` `}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`x`}</span><span parentName="span" {...{
                              "className": "mspace nobreak"
                            }}>{` `}</span><span parentName="span" {...{
                              "className": "mord"
                            }}>{`1`}</span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}>{`0`}</span><span parentName="span" {...{
                                "className": "msupsub"
                              }}><span parentName="span" {...{
                                  "className": "vlist-t"
                                }}><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.8141em"
                                      }
                                    }}><span parentName="span" {...{
                                        "style": {
                                          "top": "-3.063em",
                                          "marginRight": "0.05em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "pstrut",
                                          "style": {
                                            "height": "2.7em"
                                          }
                                        }}></span><span parentName="span" {...{
                                          "className": "sizing reset-size6 size3 mtight"
                                        }}><span parentName="span" {...{
                                            "className": "mord mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}>{`3`}</span></span></span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.7693em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.1771em",
                  "verticalAlign": "-0.686em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.4911em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`29`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`252`}</span><span parentName="span" {...{
                              "className": "mspace nobreak"
                            }}>{` `}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`x`}</span><span parentName="span" {...{
                              "className": "mspace nobreak"
                            }}>{` `}</span><span parentName="span" {...{
                              "className": "mord"
                            }}>{`1`}</span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}>{`0`}</span><span parentName="span" {...{
                                "className": "msupsub"
                              }}><span parentName="span" {...{
                                  "className": "vlist-t"
                                }}><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.8141em"
                                      }
                                    }}><span parentName="span" {...{
                                        "style": {
                                          "top": "-3.063em",
                                          "marginRight": "0.05em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "pstrut",
                                          "style": {
                                            "height": "2.7em"
                                          }
                                        }}></span><span parentName="span" {...{
                                          "className": "sizing reset-size6 size3 mtight"
                                        }}><span parentName="span" {...{
                                            "className": "mord mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}>{`3`}</span></span></span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.686em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.1141em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`8.69`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`1`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`0`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8641em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.113em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`3`}</span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.09618em"
                }
              }}>{`J`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`/`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.7144em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.113em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`o`}</span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.07153em"
                }
              }}>{`C`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`t`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`o`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`3`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`s`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.10764em"
                }
              }}>{`f`}</span></span></span></span></span></div>
    <div className='tier2 ques'>
    <span>(d)</span>
    <span>Assuming that the energy loss to the container and the surroundings are negligible, what physical quantity does this gradient represent?</span>
    </div>
    <p><strong parentName="p">{`Solution`}</strong></p>
    <p>{`The gradient represents the heat capacity of the water. The heat capacity of a substance is the quantity of energy needed to raise the temperature of that substance by 1 K (or 1 `}<sup>{`o`}</sup>{`C).`}</p>
    <div className='tier2 ques'>
    <span>(e)</span>
    <span>Determine the value of this quantity if 1 kg of water were used and state the physical quantity this represents.</span>
    </div>
    <p><strong parentName="p">{`Solution`}</strong></p>
    <p>{`If 1 kg of water were used instead, then the value would be half of the value calculated in part (c), which give us 4.35 x 10`}<sup>{`3`}</sup>{` J/`}<sup>{`o`}</sup>{`C.
The physical quantity that this represents would be the `}<u>{`specific`}</u>{` heat capacity of the water.`}</p>
    <p>{`This is so, because the formula showing the relationship between heat capacity and specific heat capacity is,`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`C`}</mi><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`m`}</mi><mi parentName="mrow">{`c`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`C = mc`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.07153em"
                }
              }}>{`C`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`m`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`c`}</span></span></span></span></span></div>
    <p>{`where,
'C' is the heat capacity,
'm' is the mass of the substance, and
'c' is the specific heat capacity. (Please note that this is a lowercase `}<inlineCode parentName="p">{`c`}</inlineCode>{`)`}</p>
    <p>{`The experiment's results determined the heat capacity (C) of 2 kg (m) of water, so now we have,`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mn parentName="mrow">{`8.69`}</mn><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mn parentName="mrow">{`1`}</mn><msup parentName="mrow"><mn parentName="msup">{`0`}</mn><mn parentName="msup">{`3`}</mn></msup><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`2`}</mn><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`c`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`8.69~x~10^{3} = 2~x~c`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8641em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`8.69`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`1`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`0`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8641em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.113em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`3`}</span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`2`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`c`}</span></span></span></span></span></div>
    <p>{`Therefore, the specific heat capacity, which can be thought of as the heat capacity per kg, is determined by solving for 'c'.`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`c`}</mi><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mrow parentName="mfrac"><mn parentName="mrow">{`8.69`}</mn><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mn parentName="mrow">{`1`}</mn><msup parentName="mrow"><mn parentName="msup">{`0`}</mn><mn parentName="msup">{`3`}</mn></msup></mrow><mn parentName="mfrac">{`2`}</mn></mfrac><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`J`}</mi><msup parentName="mrow"><mi parentName="msup" {...{
                      "mathvariant": "normal"
                    }}>{`/`}</mi><mi parentName="msup">{`o`}</mi></msup><mi parentName="mrow">{`C`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`c = \\frac{8.69~x~10^{3}}{2}~J/^{o}C`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`c`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.1771em",
                  "verticalAlign": "-0.686em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.4911em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`2`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`8.69`}</span><span parentName="span" {...{
                              "className": "mspace nobreak"
                            }}>{` `}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`x`}</span><span parentName="span" {...{
                              "className": "mspace nobreak"
                            }}>{` `}</span><span parentName="span" {...{
                              "className": "mord"
                            }}>{`1`}</span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}>{`0`}</span><span parentName="span" {...{
                                "className": "msupsub"
                              }}><span parentName="span" {...{
                                  "className": "vlist-t"
                                }}><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.8141em"
                                      }
                                    }}><span parentName="span" {...{
                                        "style": {
                                          "top": "-3.063em",
                                          "marginRight": "0.05em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "pstrut",
                                          "style": {
                                            "height": "2.7em"
                                          }
                                        }}></span><span parentName="span" {...{
                                          "className": "sizing reset-size6 size3 mtight"
                                        }}><span parentName="span" {...{
                                            "className": "mord mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}>{`3`}</span></span></span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.686em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.09618em"
                }
              }}>{`J`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`/`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.7144em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.113em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`o`}</span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.07153em"
                }
              }}>{`C`}</span></span></span></span></span></div>
    <div className='tier2 ques'>
    <span>(f)</span>
    <span>Water boils at 100<sup>o</sup>C. Calculate the heat energy which must be supplied in order to completely convert the 
    2 kg of water to steam.</span>
    </div>
    <p className='ques'>[Specific latent heat of vaporization of water = 2.3 x 10<sup>6</sup> Jkg<sup>-1</sup>]</p>
    <p><strong parentName="p">{`Solution`}</strong></p>
    <p>{`Here, the examiner gives us a hint. `}</p>
    <p>{`The specific latent heat of vaporization is the quantity of heat required to change 1 kg of a liquid to 1 kg of a vapour without any change in temperature, at standard pressure.`}</p>
    <p>{`What's most important for answering this question is that we remember the formula for calculating the specific latent heat of vaporization which is given by,`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`l`}</mi><mi parentName="msub">{`v`}</mi></msub><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><msub parentName="mfrac"><mi parentName="msub">{`E`}</mi><mi parentName="msub">{`H`}</mi></msub><mi parentName="mfrac">{`m`}</mi></mfrac><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`J`}</mi><mi parentName="mrow">{`k`}</mi><msup parentName="mrow"><mi parentName="msup">{`g`}</mi><mrow parentName="msup"><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow></msup></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`l_v = \\frac{E_H}{m}~Jkg^{-1}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8444em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.01968em"
                  }
                }}>{`l`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.1514em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0197em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight",
                              "style": {
                                "marginRight": "0.03588em"
                              }
                            }}>{`v`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.0463em",
                  "verticalAlign": "-0.686em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.3603em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`m`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.05764em"
                                }
                              }}>{`E`}</span><span parentName="span" {...{
                                "className": "msupsub"
                              }}><span parentName="span" {...{
                                  "className": "vlist-t vlist-t2"
                                }}><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.3283em"
                                      }
                                    }}><span parentName="span" {...{
                                        "style": {
                                          "top": "-2.55em",
                                          "marginLeft": "-0.0576em",
                                          "marginRight": "0.05em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "pstrut",
                                          "style": {
                                            "height": "2.7em"
                                          }
                                        }}></span><span parentName="span" {...{
                                          "className": "sizing reset-size6 size3 mtight"
                                        }}><span parentName="span" {...{
                                            "className": "mord mathnormal mtight",
                                            "style": {
                                              "marginRight": "0.08125em"
                                            }
                                          }}>{`H`}</span></span></span></span><span parentName="span" {...{
                                      "className": "vlist-s"
                                    }}>{`​`}</span></span><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.15em"
                                      }
                                    }}><span parentName="span"></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.686em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.09618em"
                }
              }}>{`J`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03148em"
                }
              }}>{`k`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`g`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8641em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.113em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`−`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span></span></span></span></span></span></span></span></span></div>
    <p>{`where, 'l`}<sub>{`v`}</sub>{`' is the specific latent heat of vaporization in J/kg (or Jkg`}<sup>{`-1`}</sup>{`). 'E`}<sub>{`H`}</sub>{`', in Joules(J), is the quantity of heat that changes the substance from liquid to vapour without any temperature change,
and 'm' is mass, in kg, of the substance undergoing the change of state.`}</p>
    <p>{`Therefore, the heat energy that must be supplied is given by,`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`E`}</mi><mi parentName="msub">{`H`}</mi></msub><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`m`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><msub parentName="mrow"><mi parentName="msub">{`l`}</mi><mi parentName="msub">{`v`}</mi></msub><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><msub parentName="mrow"><mi parentName="msub">{`E`}</mi><mi parentName="msub">{`H`}</mi></msub><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`2`}</mn><mi parentName="mrow">{`k`}</mi><mi parentName="mrow">{`g`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mn parentName="mrow">{`2.3`}</mn><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mn parentName="mrow">{`1`}</mn><msup parentName="mrow"><mn parentName="msup">{`0`}</mn><mn parentName="msup">{`6`}</mn></msup><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`J`}</mi><mi parentName="mrow">{`k`}</mi><msup parentName="mrow"><mi parentName="msup">{`g`}</mi><mrow parentName="msup"><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow></msup><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><msub parentName="mrow"><mi parentName="msub">{`E`}</mi><mi parentName="msub">{`H`}</mi></msub><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`4.6`}</mn><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mn parentName="mrow">{`1`}</mn><msup parentName="mrow"><mn parentName="msup">{`0`}</mn><mn parentName="msup">{`6`}</mn></msup><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`J`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`E_H = m~x~l_v\\\\ E_H = 2kg~x~(2.3~x~10^{6})~Jkg^{-1}\\\\ E_H = 4.6~x~10^6~J`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8333em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.05764em"
                  }
                }}>{`E`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3283em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0576em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight",
                              "style": {
                                "marginRight": "0.08125em"
                              }
                            }}>{`H`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8444em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`m`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.01968em"
                  }
                }}>{`l`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.1514em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0197em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight",
                              "style": {
                                "marginRight": "0.03588em"
                              }
                            }}>{`v`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8333em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.05764em"
                  }
                }}>{`E`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3283em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0576em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight",
                              "style": {
                                "marginRight": "0.08125em"
                              }
                            }}>{`H`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.1141em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`2`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03148em"
                }
              }}>{`k`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`g`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`2.3`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`1`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`0`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8641em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.113em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`6`}</span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.09618em"
                }
              }}>{`J`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03148em"
                }
              }}>{`k`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`g`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8641em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.113em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`−`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8333em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.05764em"
                  }
                }}>{`E`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3283em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0576em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight",
                              "style": {
                                "marginRight": "0.08125em"
                              }
                            }}>{`H`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8641em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`4.6`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`1`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`0`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8641em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.113em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`6`}</span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.09618em"
                }
              }}>{`J`}</span></span></span></span></span></div>
    <div className='tier2 ques'>
    <span>(g)</span>
    <span>Distinguish between boiling and evaporation.</span>
    </div>
    <p><strong parentName="p">{`Solution`}</strong></p>
    <p>{`Here are some of the key differences between boiling and evaporation:`}</p>
    <ul>
    <li>Boiling takes place within a liquid while evaporation takes place at the surface of a liquid.</li>
    <li>Boiling takes place at a specified temperature while evaporation takes place at many different temperatures.</li>
    <li>For boiling to take place, continuous heat energy must be supplied to the liquid. Heat energy does not need to be supplied in order for evaporation to occur.</li>
    </ul>
    <div className='tier1-2 ques'>
    <span>2</span>
    <span>(a)</span>
    <span>Explain what is meant by 'electrical resistance'.</span>
    </div>
    <p><strong parentName="p">{`Solution`}</strong></p>
    <p>{`Electrical resistance is the opposition to the flow of electrons in a circuit.`}</p>
    <div className='tier2 ques'>
    <span>(b)</span>
    <span>The circuit shown in Figure 1 shows the interconnection between electrical devices, <b>B</b> and <b>C</b>, and voltage source <b>A</b>.</span>
    </div>
    <p><img parentName="p" {...{
        "src": "https://cxcanswers.s3.us-east-2.amazonaws.com/csec-physics-2006-fig1.PNG",
        "alt": "Figure1"
      }}></img></p>
    <div className='tier3 ques'>
    <span>(i)</span>
    <span>Identify the type of connection employed in this circuit.</span>
    </div>
    <p><strong parentName="p">{`Solution`}</strong></p>
    <p>{`The type of connection is a parallel connection.`}</p>
    <div className='tier3 ques'>
    <span>(ii)</span>
    <span>Give the meaning of the circuit symbols labelled: B and C.</span>
    </div>
    <p><strong parentName="p">{`Solution`}</strong></p>
    <p>{`B represents an electic motor and C represents a light bulb.`}</p>
    <div className='tier3 ques'>
    <span>(iii)</span>
    <span>Name the type of voltage produced at A.</span>
    </div>
    <p><strong parentName="p">{`Solution`}</strong></p>
    <p>{`The voltage produced at A is an alternating voltage.`}</p>
    <div className='tier2-1 ques'>
    <span>(c)</span>
    <span>(i)</span>
    <span>A fluoroscent lamp is rated at 15W, 120V. Calculate the current which the lamp draws from the main supply.</span>
    </div>
    <p><strong parentName="p">{`Solution`}</strong></p>
    <p>{`The relationship between power, voltage and current is given by:`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`P`}</mi><mi parentName="mrow">{`o`}</mi><mi parentName="mrow">{`w`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`r`}</mi><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`C`}</mi><mi parentName="mrow">{`u`}</mi><mi parentName="mrow">{`r`}</mi><mi parentName="mrow">{`r`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`n`}</mi><mi parentName="mrow">{`t`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`V`}</mi><mi parentName="mrow">{`o`}</mi><mi parentName="mrow">{`l`}</mi><mi parentName="mrow">{`t`}</mi><mi parentName="mrow">{`a`}</mi><mi parentName="mrow">{`g`}</mi><mi parentName="mrow">{`e`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`Power=Current~x~Voltage`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`o`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.02691em"
                }
              }}>{`w`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.02778em"
                }
              }}>{`er`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8889em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.07153em"
                }
              }}>{`C`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`u`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`rre`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`t`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.22222em"
                }
              }}>{`V`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`o`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`lt`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`g`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`e`}</span></span></span></span></span></p>
    <p>{`Therefore, the current can be determined by modifying the formula above:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`C`}</mi><mi parentName="mrow">{`u`}</mi><mi parentName="mrow">{`r`}</mi><mi parentName="mrow">{`r`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`n`}</mi><mi parentName="mrow">{`t`}</mi><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mrow parentName="mfrac"><mi parentName="mrow">{`P`}</mi><mi parentName="mrow">{`o`}</mi><mi parentName="mrow">{`w`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`r`}</mi></mrow><mrow parentName="mfrac"><mi parentName="mrow">{`V`}</mi><mi parentName="mrow">{`o`}</mi><mi parentName="mrow">{`l`}</mi><mi parentName="mrow">{`t`}</mi><mi parentName="mrow">{`a`}</mi><mi parentName="mrow">{`g`}</mi><mi parentName="mrow">{`e`}</mi></mrow></mfrac><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><mi parentName="mrow">{`C`}</mi><mi parentName="mrow">{`u`}</mi><mi parentName="mrow">{`r`}</mi><mi parentName="mrow">{`r`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`n`}</mi><mi parentName="mrow">{`t`}</mi><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mn parentName="mfrac">{`15`}</mn><mn parentName="mfrac">{`120`}</mn></mfrac><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><mi parentName="mrow">{`C`}</mi><mi parentName="mrow">{`u`}</mi><mi parentName="mrow">{`r`}</mi><mi parentName="mrow">{`r`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`n`}</mi><mi parentName="mrow">{`t`}</mi><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`0.125`}</mn><mi parentName="mrow">{`A`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`Current=\\frac{Power}{Voltage}\\\\ Current=\\frac{15}{120}\\\\ Current=0.125A`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.07153em"
                }
              }}>{`C`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`u`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`rre`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`t`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.2408em",
                  "verticalAlign": "-0.8804em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.3603em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.22222em"
                              }
                            }}>{`V`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`o`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`lt`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`a`}</span><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.03588em"
                              }
                            }}>{`g`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`e`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.13889em"
                              }
                            }}>{`P`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`o`}</span><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.02691em"
                              }
                            }}>{`w`}</span><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.02778em"
                              }
                            }}>{`er`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8804em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.07153em"
                }
              }}>{`C`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`u`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`rre`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`t`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.0074em",
                  "verticalAlign": "-0.686em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.3214em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`120`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`15`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.686em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.07153em"
                }
              }}>{`C`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`u`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`rre`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`t`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`0.125`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`A`}</span></span></span></span></span></div>
    <div className='tier3 ques'>
    <span>(ii)</span>
    <span>Calculate the resistance of this lamp while it is lit.</span>
    </div>
    <p><strong parentName="p">{`Solution`}</strong></p>
    <p>{`Ohm's law gives us the relationship between the voltage, current and electrical resistance as:`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`V`}</mi><mi parentName="mrow">{`o`}</mi><mi parentName="mrow">{`l`}</mi><mi parentName="mrow">{`t`}</mi><mi parentName="mrow">{`a`}</mi><mi parentName="mrow">{`g`}</mi><mi parentName="mrow">{`e`}</mi><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`C`}</mi><mi parentName="mrow">{`u`}</mi><mi parentName="mrow">{`r`}</mi><mi parentName="mrow">{`r`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`n`}</mi><mi parentName="mrow">{`t`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`R`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`s`}</mi><mi parentName="mrow">{`i`}</mi><mi parentName="mrow">{`s`}</mi><mi parentName="mrow">{`t`}</mi><mi parentName="mrow">{`a`}</mi><mi parentName="mrow">{`n`}</mi><mi parentName="mrow">{`c`}</mi><mi parentName="mrow">{`e`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`Voltage=Current~x~Resistance`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8889em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.22222em"
                }
              }}>{`V`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`o`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`lt`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`g`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`e`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.07153em"
                }
              }}>{`C`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`u`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`rre`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`t`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.00773em"
                }
              }}>{`R`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`es`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`i`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`s`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`t`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`an`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`ce`}</span></span></span></span></span></p>
    <p>{`Therefore, the resistance can be determined by modifying the formula above:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`R`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`s`}</mi><mi parentName="mrow">{`i`}</mi><mi parentName="mrow">{`s`}</mi><mi parentName="mrow">{`t`}</mi><mi parentName="mrow">{`a`}</mi><mi parentName="mrow">{`n`}</mi><mi parentName="mrow">{`c`}</mi><mi parentName="mrow">{`e`}</mi><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mrow parentName="mfrac"><mi parentName="mrow">{`V`}</mi><mi parentName="mrow">{`o`}</mi><mi parentName="mrow">{`l`}</mi><mi parentName="mrow">{`t`}</mi><mi parentName="mrow">{`a`}</mi><mi parentName="mrow">{`g`}</mi><mi parentName="mrow">{`e`}</mi></mrow><mrow parentName="mfrac"><mi parentName="mrow">{`C`}</mi><mi parentName="mrow">{`u`}</mi><mi parentName="mrow">{`r`}</mi><mi parentName="mrow">{`r`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`n`}</mi><mi parentName="mrow">{`t`}</mi></mrow></mfrac><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><mi parentName="mrow">{`R`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`s`}</mi><mi parentName="mrow">{`i`}</mi><mi parentName="mrow">{`s`}</mi><mi parentName="mrow">{`t`}</mi><mi parentName="mrow">{`a`}</mi><mi parentName="mrow">{`n`}</mi><mi parentName="mrow">{`c`}</mi><mi parentName="mrow">{`e`}</mi><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mn parentName="mfrac">{`120`}</mn><mn parentName="mfrac">{`0.125`}</mn></mfrac><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`Resistance=\\frac{Voltage}{Current}\\\\ Resistance=\\frac{120}{0.125}\\\\`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.00773em"
                }
              }}>{`R`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`es`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`i`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`s`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`t`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`an`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`ce`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.0574em",
                  "verticalAlign": "-0.686em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.3714em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.07153em"
                              }
                            }}>{`C`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`u`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`rre`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`n`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`t`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.22222em"
                              }
                            }}>{`V`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`o`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`lt`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`a`}</span><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.03588em"
                              }
                            }}>{`g`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`e`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.686em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.00773em"
                }
              }}>{`R`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`es`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`i`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`s`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`t`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`an`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`ce`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.0074em",
                  "verticalAlign": "-0.686em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.3214em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`0.125`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`120`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.686em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span></span></span></span></div>
    <p>{`Resistance = 960 `}{`Ω`}</p>
    <div className='tier3 ques'>
    <span>(iii)</span>
    <span>When the lamp is lit, 4 W of the energy supplied is lost as heat. Calculate the efficiency of the lamp.</span>
    </div>
    <p>{`Efficiency is given by the following:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`E`}</mi><mi parentName="mrow">{`f`}</mi><mi parentName="mrow">{`f`}</mi><mi parentName="mrow">{`i`}</mi><mi parentName="mrow">{`c`}</mi><mi parentName="mrow">{`i`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`n`}</mi><mi parentName="mrow">{`c`}</mi><mi parentName="mrow">{`y`}</mi><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mrow parentName="mfrac"><mi parentName="mrow">{`U`}</mi><mi parentName="mrow">{`s`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`f`}</mi><mi parentName="mrow">{`u`}</mi><mi parentName="mrow">{`l`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`o`}</mi><mi parentName="mrow">{`u`}</mi><mi parentName="mrow">{`t`}</mi><mi parentName="mrow">{`p`}</mi><mi parentName="mrow">{`u`}</mi><mi parentName="mrow">{`t`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`n`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`r`}</mi><mi parentName="mrow">{`g`}</mi><mi parentName="mrow">{`y`}</mi></mrow><mrow parentName="mfrac"><mi parentName="mrow">{`T`}</mi><mi parentName="mrow">{`o`}</mi><mi parentName="mrow">{`t`}</mi><mi parentName="mrow">{`a`}</mi><mi parentName="mrow">{`l`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`i`}</mi><mi parentName="mrow">{`n`}</mi><mi parentName="mrow">{`p`}</mi><mi parentName="mrow">{`u`}</mi><mi parentName="mrow">{`t`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`n`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`r`}</mi><mi parentName="mrow">{`g`}</mi><mi parentName="mrow">{`y`}</mi></mrow></mfrac><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><mi parentName="mrow">{`E`}</mi><mi parentName="mrow">{`f`}</mi><mi parentName="mrow">{`f`}</mi><mi parentName="mrow">{`i`}</mi><mi parentName="mrow">{`c`}</mi><mi parentName="mrow">{`i`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`n`}</mi><mi parentName="mrow">{`c`}</mi><mi parentName="mrow">{`y`}</mi><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mrow parentName="mfrac"><mn parentName="mrow">{`15`}</mn><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`4`}</mn></mrow><mn parentName="mfrac">{`15`}</mn></mfrac><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><mi parentName="mrow">{`E`}</mi><mi parentName="mrow">{`f`}</mi><mi parentName="mrow">{`f`}</mi><mi parentName="mrow">{`i`}</mi><mi parentName="mrow">{`c`}</mi><mi parentName="mrow">{`i`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`n`}</mi><mi parentName="mrow">{`c`}</mi><mi parentName="mrow">{`y`}</mi><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mn parentName="mfrac">{`11`}</mn><mn parentName="mfrac">{`15`}</mn></mfrac><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`Efficiency = \\frac{Useful~output~energy}{Total~input~energy}\\\\ Efficiency = \\frac{15 - 4}{15}\\\\ Efficiency = \\frac{11}{15}\\\\`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8889em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.05764em"
                }
              }}>{`E`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.10764em"
                }
              }}>{`ff`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`i`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`c`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`i`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`e`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`cy`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.2519em",
                  "verticalAlign": "-0.8804em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.3714em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.13889em"
                              }
                            }}>{`T`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`o`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`t`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`a`}</span><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.01968em"
                              }
                            }}>{`l`}</span><span parentName="span" {...{
                              "className": "mspace nobreak"
                            }}>{` `}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`in`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`p`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`u`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`t`}</span><span parentName="span" {...{
                              "className": "mspace nobreak"
                            }}>{` `}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`e`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`n`}</span><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.02778em"
                              }
                            }}>{`er`}</span><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.03588em"
                              }
                            }}>{`g`}</span><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.03588em"
                              }
                            }}>{`y`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.10903em"
                              }
                            }}>{`U`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`se`}</span><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.10764em"
                              }
                            }}>{`f`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`u`}</span><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.01968em"
                              }
                            }}>{`l`}</span><span parentName="span" {...{
                              "className": "mspace nobreak"
                            }}>{` `}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`o`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`u`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`tp`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`u`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`t`}</span><span parentName="span" {...{
                              "className": "mspace nobreak"
                            }}>{` `}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`e`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`n`}</span><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.02778em"
                              }
                            }}>{`er`}</span><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.03588em"
                              }
                            }}>{`g`}</span><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.03588em"
                              }
                            }}>{`y`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8804em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8889em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.05764em"
                }
              }}>{`E`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.10764em"
                }
              }}>{`ff`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`i`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`c`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`i`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`e`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`cy`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.0074em",
                  "verticalAlign": "-0.686em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.3214em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`15`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`15`}</span><span parentName="span" {...{
                              "className": "mspace",
                              "style": {
                                "marginRight": "0.2222em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mbin"
                            }}>{`−`}</span><span parentName="span" {...{
                              "className": "mspace",
                              "style": {
                                "marginRight": "0.2222em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}>{`4`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.686em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8889em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.05764em"
                }
              }}>{`E`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.10764em"
                }
              }}>{`ff`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`i`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`c`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`i`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`e`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`cy`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.0074em",
                  "verticalAlign": "-0.686em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.3214em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`15`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`11`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.686em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span></span></span></span></div>
    <p>{`Efficiency = 0.733 or 73.3 `}{`%`}</p>
    <div className='tier1-2 ques'>
    <span>3</span>
    <span>(a)</span>
    <span>Figure 2 shows successive stages in the generation of a transverse wave on a stretched slinky spring.</span>
    </div>
    <p><img parentName="p" {...{
        "src": "https://cxcanswers.s3.us-east-2.amazonaws.com/csec-physics-2006-fig2.PNG",
        "alt": "Figure2"
      }}></img></p>
    <div className='tier3 ques'>
    <span>(i)</span>
    <span>Indicate on Figure 2F by appropriately labelling</span>
    </div>
    <div className='tier4 ques'>
    <span>a)</span>
    <span>the position of a crest</span>
    </div>
    <p><strong parentName="p">{`Solution`}</strong></p>
    <p>{`The crest is located at the highest point of the wave.`}</p>
    <div className='tier4 ques'>
    <span>b)</span>
    <span>the position of a trough</span>
    </div>
    <p><strong parentName="p">{`Solution`}</strong></p>
    <p>{`The trough is located at the lowest point of the wave.`}</p>
    <div className='tier3 ques'>
    <span>(ii)</span>
    <span>Draw labelled arrows on Figure 2E to show</span>
    </div>
    <div className='tier4 ques'>
    <span>a)</span>
    <span>the direction of the wave</span>
    </div>
    <p><strong parentName="p">{`Solution`}</strong></p>
    <p>{`The wave is moving from the left to the right.`}</p>
    <div className='tier4 ques'>
    <span>b)</span>
    <span>the direction of hand movement needed to produce this wave.</span>
    </div>
    <p><strong parentName="p">{`Solution`}</strong></p>
    <p>{`The hand would need to move up and down in order to produce this wave.`}</p>
    <div className='tier3 ques'>
    <span>(iii)</span>
    <span>Indicate on Figure 2A the direction of hand movement needed to produce a longitudinal wave.</span>
    </div>
    <p><strong parentName="p">{`Solution`}</strong></p>
    <p>{`A longitudinal wave would move back and forth in a straight line (as seen in this `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=0Anh9HthWgQ"
      }}>{`video`}</a>{`).
In order to produce this wave, the hand would also have to move back and forth.`}</p>
    <div className='tier2 ques'>
    <span>(b)</span>
    <span>In a classroom demonstration a student uses a slinky spring to generate waves of frequency 5 Hz with 0.65 metres separation between successive crests. Determine the speed of these waves.</span>
    </div>
    <p><strong parentName="p">{`Solution`}</strong></p>
    <p>{`The relationship between frequency(f), wavelength(`}{`λ`}{`) and wave speed(v) is given by:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`λ`}</mi><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mi parentName="mfrac">{`v`}</mi><mi parentName="mfrac">{`f`}</mi></mfrac></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\lambda = \\frac{v}{f}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`λ`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.988em",
                  "verticalAlign": "-0.8804em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.1076em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.10764em"
                              }
                            }}>{`f`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.03588em"
                              }
                            }}>{`v`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8804em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span></span></span></span></div>
    <p>{`Therefore, the wave speed can be given by:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`v`}</mi><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`λ`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`f`}</mi><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><mi parentName="mrow">{`v`}</mi><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`0.65`}</mn><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mn parentName="mrow">{`5`}</mn><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><mi parentName="mrow">{`v`}</mi><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`3.25`}</mn><mi parentName="mrow">{`m`}</mi><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`/`}</mi><mi parentName="mrow">{`s`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`v = \\lambda~x~f\\\\ v = 0.65~x~5\\\\ v = 3.25 m/s`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`v`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8889em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`λ`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.10764em"
                }
              }}>{`f`}</span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`v`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`0.65`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`5`}</span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`v`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`3.25`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`m`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`/`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`s`}</span></span></span></span></span></div>
    <div className='tier2-1 ques'>
    <span>(c)</span>
    <span>(i)</span>
    <span>Deduce whether the velocity of a sound wave will increase, decrease or remain constant when the wave travels from a denser to a less dense medium, given that the frequency remains constant and the wavelength decreases.</span>
    </div>
    <div className='tier3 ques'>
    <span>(ii)</span>
    <span>Compare the waves produced on the slinky spring in part (b) with a typical sound wave in terms of the motion of the particles.</span>
    </div>
    <div className='tier4 ques'>
    <span>a)</span>
    <span>Transverse wave on slinky spring</span>
    </div>
    <div className='tier4 ques'>
    <span>b)</span>
    <span>Sound wave</span>
    </div>
    <div className='tier2 ques'>
    <span>(d)</span>
    <span>In Figure 3 below, a ship sends out pulses of high-frequency sound (ultrasound) which are reflected from the sea bed and received at the ship every 1.2 x 10<sup>-2</sup> seconds.</span>
    </div>
    <p><img parentName="p" {...{
        "src": "https://cxcanswers.s3.us-east-2.amazonaws.com/csec-physics-2006-fig3.PNG",
        "alt": "Figure3"
      }}></img></p>
    <div className='tier4 ques'>
    <span>a)</span>
    <span>Calculate the depth of the sea bed below the transmitter.</span>
    </div>
    <p className='ques'>(Speed of sound in sea water = 1,500 m s<sup>-1</sup>)</p>
    <div className='tier1-2 ques'>
    <span>4</span>
    <span>(a)</span>
    <span>Define 'the moment of a force'</span>
    </div>
    <div className='tier2 ques'>
    <span>(b)</span>
    <span>State the principle of moments.</span>
    </div>
    <div className='tier2 ques'>
    <span>(c)</span>
    <span>A cyclist hangs his bicycle with the rear wheel in contact with the ground and the handle attached to a light string as shown in Figure 4. The entire assembly is in equilibrium.</span>
    </div>
    <p><img parentName="p" {...{
        "src": "https://cxcanswers.s3.us-east-2.amazonaws.com/csec-physics-2006-fig4.PNG",
        "alt": "Figure4"
      }}></img></p>
    <div className='tier3 ques'>
    <span>(i)</span>
    <span>Indicate by drawing arrows on Figure 4 the forces acting on the bicycle.</span>
    </div>
    <div className='tier3 ques'>
    <span>(ii)</span>
    <span>Write TWO equations relating these forces.</span>
    </div>
    <div className='tier3 ques'>
    <span>(iii)</span>
    <span>The bicycle has a mass of 20 kg. Use the result you obtained in Part (c) (ii) to determine the tension in the string.</span>
    </div>
    <p className='ques'>[Acceleration due to gravity, g = 10 ms<sup>-2</sup>]</p>
    <div className='tier1-2 ques'>
    <span>5</span>
    <span>(a)</span>
    <span>Complete Table 2 below showing the International Insulation Colour Code</span>
    </div>
    <table className='ques'>
    <thead>
        <tr>
            <td>Code</td>
            <td>Live Wire</td>
            <td>Neutral Wire</td>
            <td>Earth Wire</td>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>International Insulation Colour</td>
            <td></td>
            <td></td>
            <td></td>
            </tr>
    </tbody>
    </table>
    <div className='tier2 ques'>
    <span>(b)</span>
    <span>Fuses are sometimes used to protect electrical equipment from excess current. Explain how a fuse works.</span>
    </div>
    <div className='tier2 ques'>
    <span>(c)</span>
    <span>A household has the following appliances</span>
    </div>
    <div className='tier4 ques'>
    <span>-</span>
    <span>Heating iron rated at 1200 W, 110 V</span>
    </div>
    <div className='tier4 ques'>
    <span>-</span>
    <span>Television set rated at 70 W, 110 V</span>
    </div>
    <div className='tier4 ques'>
    <span>-</span>
    <span>Electrical fan 52 W, 110 V</span>
    </div>
    <div className='tier4 ques'>
    <span>(i)</span>
    <span>Calculate the total power consumed by these devices.</span>
    </div>
    <div className='tier4 ques'>
    <span>(ii)</span>
    <span>These devices are in use for 4 hours per day. Caluclate the energy consumed in kilowatt-hours in a 30-day month.</span>
    </div>
    <div className='tier4 ques'>
    <span>(iii)</span>
    <span>Calculate the bill for the month if each unit of electricity costs 40&#162;.</span>
    </div>
    <div className='tier4 ques'>
    <span>(iv)</span>
    <span>Assuming that these devices are connected in parallel across the mains supply, and are switched ON, calculate the TOTAL current drawn from the mains.</span>
    </div>
    <div className='tier4 ques'>
    <span>(v)</span>
    <span>Fuses are available with the current ratings: 5A, 10A, 15A, 25A. Select a suitable fuse for protecting these devices.</span>
    </div>
    <h1>{`END OF TEST`}</h1>
    <Link to='/' className='button' mdxType="Link">Back to Home page</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      